<template>
    <div class="unitadd-page">
        <div class="tab-bar">
            <van-tabs v-model:active="tabActive" :animated="false" color="#DF5F0B" title-active-color="#DF5F0B">
                <van-tab replace title="添加单位" to="/client/unit/add" />
                <van-tab replace title="施工单位" to="/client/unit/list/construction" />
                <van-tab replace title="巡护单位" to="/client/unit/list/patrol" />
            </van-tabs>
        </div>
        <div class="unitadd-body">
            <div class="unitadd-item">
                <p>单位名称：</p>
                <div class="input-box">
                    <van-field v-model="dataForm.name" input-align="right" placeholder="输入单位名" />
                </div>
            </div>
            <div class="unitadd-item">
                <p>描　述：</p>
                <div class="input-box">
                    <van-field v-model="dataForm.description" input-align="right" placeholder="输入单位描述" />
                </div>
            </div>
            <div class="unitadd-item">
                <gk-select
                    v-model:active="typePicker.active"
                    v-model:show="typePicker.show"
                    v-model:value="typePicker.value"
                    :columns="typePicker.list"
                    label="单位类型"
                    @confirm="dataForm.type = typePicker.active.id"
                />
            </div>
            <div class="unitadd-item">
                <p>是否启用：</p>
                <van-switch v-model="dataForm.status" :active-value="1" :inactive-value="0" size="20" />
            </div>
        </div>
        <div class="save-info-button">
            <van-button :block="true" color="#DF5F0B" @click="onSubmit">
                {{ $route.query.id ? "确认修改" : "确认添加" }}
            </van-button>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { ADD_UNIT, GET_UNIT_DETAIL, UPDATE_UNIT } from "@/api/goods";
import { Toast } from "vant";
import usePublic from "@/hooks/usePublic";

let { $route, $router } = usePublic();

onMounted(() => {
    if ($route.query.id) getDataDetail();
});

// 当前tab
let tabActive = ref(0);

let getDataDetail = () => {
    GET_UNIT_DETAIL({ id: $route.query.id }).then((res) => {
        let detailData = res.data.data;
        console.log(res.data.data);
        dataForm.value = {
            name: detailData.name,
            description: detailData.description,
            status: detailData.status,
            type: detailData.type
        };
        typePicker.value.active = {
            id: detailData.type,
            text: detailData.type === 1 ? "巡护单位" : "施工单位"
        };
    });
};

// 类型选择器
let typePicker = ref({
    show: false,
    value: "请选择单位类型",
    active: {},
    list: [
        {
            id: 1,
            text: "巡护单位"
        },
        {
            id: 2,
            text: "施工单位"
        }
    ]
});

let dataForm = ref({
    name: null,
    description: null,
    status: 1,
    type: null
});

let onSubmit = () => {
    if ($route.query.id) {
        UPDATE_UNIT({
            ...dataForm.value,
            id: $route.query.id
        }).then((res) => {
            console.log(res.data);
            if (res.data.code === 200) {
                Toast({
                    type: "success",
                    message: "修改成功",
                    onClose: () => {
                        $router.go(-1);
                    }
                });
            }
        });
        return false;
    }
    ADD_UNIT({ ...dataForm.value }).then((res) => {
        console.log(res.data);
        if (res.data.code === 200) {
            Toast({
                type: "success",
                message: "新增成功",
                onClose: () => {
                    location.reload();
                }
            });
        }
    });
};
</script>

<style lang="scss" scoped>
.unitadd-page {
    min-height: 100vh;
    padding-bottom: 60px;
    background: #f0f0f0;

    .tab-bar {
        position: sticky;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 9;
    }

    .unitadd-body {
        margin-top: 10px;
        min-height: 50px;
        background: #fff;
        padding: 20px 15px 5px 15px;
        display: flex;
        flex-direction: column;

        .unitadd-item:nth-child(1) {
            padding-top: 0;
        }

        .unitadd-item {
            font-size: 14px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #eee;
            padding: 15px 0;

            .input-box {
                width: 140px;
            }

            .gk-select {
                width: 100%;

                :deep(.van-cell) {
                    &:after {
                        display: none;
                    }

                    padding: 0;
                }
            }
        }

        .unitadd-item:nth-child(4) {
            border: none;
        }
    }

    .save-info-button {
        padding: 14px;
    }
}
</style>
